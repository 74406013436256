import {Component, EventEmitter, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FenceFacadeService} from '@app/services/fence/fence-facade.service';
import {FenceResultFacadeService} from '@app/services/fence-result/fence-result-facade.service';
import {ResultCodeFacadeService} from '@app/services/result-code/result-code-facade.service';
import {RiderFacadeService} from '@app/services/rider/riders-facade.service';
import {DataItem} from '@app/components/shared/model/data-item';
import {RiderExtendedSignalR} from '@app/rest-client/faxe/models/rider-extended-signal-r';

@Component({
  selector: 'faxe-rider-select-chip',
  templateUrl: './rider-select-chip.component.html',
  styleUrls: ['./rider-select-chip.component.scss']
})
export class RiderSelectChipComponent {

 // @Output() itemSelectedEvent: EventEmitter<DataItem<T>> = new EventEmitter<DataItem<T>>();
  formGroup: UntypedFormGroup = this.formBuilder.group({});
  riderDataItem: DataItem<RiderExtendedSignalR>[];


  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly fenceFacadeService: FenceFacadeService,
              private readonly fenceResultFacadeService: FenceResultFacadeService,
              private readonly resultCodeFacadeService: ResultCodeFacadeService,
              private readonly riderFacadeService: RiderFacadeService,
  ) {
  }

  onRiderSelected($event: DataItem<RiderExtendedSignalR>) {

  }

  onRiderLastUnSelected() {

  }
}
