import {Injectable} from '@angular/core';
import {selectRider} from '@app/store/faxe/rider/rider.selectos';
import {combineLatestWith, Observable} from 'rxjs';
import {RiderView} from '@app/services/rider-view/model/rider-view';
import {selectFenceResult} from '@app/store/faxe/fence-result/fence-result.selectos';
import {map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Rider} from '@app/rest-client/faxe/models/rider';
import {FenceResult} from '@app/rest-client/faxe/models/fence-result';
import {selectRiderEvent} from '@app/store/faxe/rider-event/rider-event.selectos';
import {RiderEvent} from '@app/rest-client/faxe/models/rider-event';

@Injectable({
  providedIn: 'root'
})
export class RiderViewService {

  constructor(private store: Store<RootState>) {
  }

  public selectRiderView(): Observable<RiderView[]> {
    // fetch
    //fetch
    //fetch
    return this.selectRider()
      .pipe(combineLatestWith(this.selectFenceResult(), this.selectRiderEvent()),
        map(([riders, fenceResults, riderEvents]) => this.map(riders, fenceResults, riderEvents))
      );
  }

  private selectRider(): Observable<Rider[]> {
    return this.store.pipe(
      select(selectRider()));
  }

  private selectFenceResult(): Observable<FenceResult[]> {
    return this.store.pipe(
      select(selectFenceResult()));
  }

  private selectRiderEvent(): Observable<RiderEvent[]> {
    return this.store.pipe(
      select(selectRiderEvent()));
  }

  private map(riders: Rider[], fenceResults: FenceResult[], riderEvents: RiderEvent[] ): RiderView[] {
    return riders.map(rider => ({
      ...rider,
      fenceResults: fenceResults.filter(fr => fr.riderId === rider.riderId),
      riderEvents: riderEvents.filter(re => re.riderId === rider.riderId)
    }));
  }
}
